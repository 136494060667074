@import 'Styles/colors.module.scss';
@import 'Styles/spacing.module.scss';
@import 'Styles/breakpoints.module.scss';
@import 'Styles/transition.module.scss';


@keyframes slidein {
    from {
        transform: translateX(50vw);
        opacity: 0;
    }

    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slideout {
    from {
        transform: translateX(0%);
        opacity: 1;
    }

    to {
        transform: translateX(50vw);
        opacity: 0;
    }
}

.container {
    position: fixed;
    top: $space-xl;
    width: 320px;
    max-width: 100vw;
    z-index: 999999;
    right: 48%;
    transform: translate(50%);
}

.notification {
    position: relative;
    padding: $space-s;
    border-radius: 5px;
    margin-bottom: 10px;

    width: 90%;
    word-break: break-word;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

    animation-name: slidein;
    animation-duration: 200ms;
    animation-iteration-count: 1;


    .message {
        padding-right: $space-l;
        display: flex;
        align-items: center;
        padding: $space-s;

    }

    .notificationIcon {
        margin-right: $space-s;
        //flex: 1;
        flex-shrink: 0;
    }

    .dismiss {
        position: absolute;
        top: -.15rem;
        right: -.15rem;
        padding: $space-s;
        cursor: pointer;
    }

    &.dismissing {
        position: absolute; // reduces "Snapping" from happening when a notification is sliding in at the same time as one is sliding out
        animation-name: slideout;
        animation-duration: 200ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

}

@media (min-width: 1280px) {
    .container {
        right: $space-xs;
        top: $space-xs;
        width: 320px;
        max-width: 100vw;
        z-index: 999999;
        transform: unset;
    }
}