
.center {
  text-align: center;
}

.kit_drawer_header {
  margin: 1rem;
}

.kit_drawer_atc {
  margin-top: 3rem;
}

.kit_drawer_panel {
  padding: 0.5rem;
  flex-grow: 1;
}

.kit_drawer {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

@media (min-width: 460px){
  .kit_drawer_panel {
    padding: 1.5rem;
  }
}