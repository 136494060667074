.skeleton {
    margin-bottom: 8px;
    background: #f6f7f8;
    background-image: linear-gradient(to right, #eaebec 0%, #d7d7d8 20%, #eaebec 40%, #eaebec 100%);
    background-repeat: no-repeat;
    background-size: 400% 100%;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.darkSkeleton {
    margin-bottom: 8px;
    background: #000000;
    background-image: linear-gradient(to right, #161616 0%, #161616 20%, #161616 40%, #161616 100%);
    background-repeat: no-repeat;
    background-size: 400% 100%;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

@-webkit-keyframes placeholderShimmer {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}

@keyframes placeholderShimmer {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}