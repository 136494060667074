@import 'Styles/breakpoints.module.scss';

.button {

    height: 3rem;
    margin-left: 0.25rem;
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    //display: flex;
}

.button svg {
    max-width: 100%;
    transform: translateY(-1px);
}

@media screen {}


@include pwaMediaQuery {
    .button {
        display: flex;
    }
}

@include desktopMediaQuery {
    .button {
        width: 1.5rem;
    }
}

@include tabletMediaQuery {
    .button {
        width: 1.5rem;
    }
}