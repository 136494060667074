.addRecommendationsToCartButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem
}

.title {
  margin-bottom: 0.25rem;
}

.recWrapper {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 0.5rem;

  .rec {
    width: 100%;
    margin: 0 auto;
  }
}

.divider {
  margin-bottom: 1rem;
}

.noDivider {
  @extend .divider;
  height: 1px;
}