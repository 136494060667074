.summary_recommendations_container {
  margin-bottom: 2rem;
}

.title {
  margin-bottom: 0.25rem;
}

.carousel{
  z-index: 0;
}

.carousel_item {
  min-width: 12rem;
  display: inline-flex;
  flex-direction: column;
}

.carousel_term_item {
  display: inline-flex;
  flex-direction: column;
}

.skeleton_item {
  min-width: 12rem;
  display: inline-flex;
  flex-direction: column;
  margin: 0 0.5rem;
}

.carousel_wrapper {
  overflow: visible;
}

.ribbon {
  width: 4rem;
  margin-bottom: 0.75rem;

  &.ribbon_hidden {
    visibility: hidden;
  }
}

.rec_image {
  margin-right: auto;
  margin-left: auto;
}

.rec_display_title {
  white-space: pre-wrap;
}

.rec_rating {
  margin: auto;
  margin-bottom: 0;
  margin-left: 0;
}

.divider {
  margin-bottom: 1rem;
}

.noDivider {
  @extend .divider;
  height: 1px;
}

.svg {
  justify-content: space-between;
  display: flex;
  align-items: center;
  z-index: 1;
}