@import 'Styles/transition.module.scss';

.checkbox {
    margin-right: 0.5rem;
    @include transition(margin-right $fast);

    &:hover {
        margin-right: 1rem;
    }
}

.noTransition{
    margin-right: 0.5rem;
}

.verticalCheckbox {
    display: flex;
    align-items: center
}

.label{
    cursor: pointer;
}