@import 'Styles/transition.module.scss';
@import 'Styles/fonts.module.scss';

.typography {
    margin: 0;


}
.link {
    text-decoration: none;
    @include transition(color $fast);
    
    &:hover {
        text-decoration: none;
    }
}

.hoverable {
    cursor: pointer;
    @include transition(color $fast);
}

.underline {
    text-decoration: underline;
}

.strikethrough {
    text-decoration: line-through;
}

.regular{
    @include fontStandard();
}

.bold{
    @include fontBold();
}

.heading{
    @include fontHeading(); 
}