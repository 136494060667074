@import 'Styles/spacing.module.scss';
@import 'Styles/colors.module.scss';
@import 'Styles/transition.module.scss';

.switchWrapper {
    position: relative;
    width: 2.5rem;
    height: 1.5rem;
    display: inline-block;
    vertical-align: middle;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .checkbox {
      display: none;
      
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .label {
      display: block;
      overflow: hidden;
      cursor: pointer;
      border-radius: 2rem;
      width: 2.5rem;
      height: 1.5rem;

      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .switchDisabled {
      cursor: default;
    }

    .switch {
      width: 1rem;
      margin: 0.25rem;
      background: $light;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-radius: 2rem;

      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      box-shadow: rgba(0, 0, 0, 0.6) 0 0 0.25rem 0;

      @include transition(left $fast);
    }

    &.activeCheckbox {
      .switch {
        left: 1rem;
      }
    }
  }