.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    gap: 0.5rem;
}

.buttonContainer {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.textArea {
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    width: 100%;
    max-width: 20rem;
}

.submitButton {}

.switch {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}