@import 'Styles/spacing.module.scss';
@import 'Styles/breakpoints.module.scss';

.ymmPillText {
    padding-right: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ymmPillX {
    top: 1px;
    position: relative;
    cursor: pointer;
}

.ymmPill {
    background-image: none;
    border-radius: 16rem;
    max-width: 100%;
    display: inline-flex;
    align-items: center;
    overflow: hidden;

    @include mobileMediaQuery {
        margin-top: 5px;
    }

    @include desktopMediaQuery {
        max-width: 300px;
    }
}

.isDesktop{
    margin: auto
  }

.filteringBy {
    margin-top: 10px;
}

@include mobileMediaQuery {
    .isMobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .isDesktop {
        display: none;
    }
}

@include tabletMediaQuery {
    .isMobile {
        display: none;
    }
}

@include desktopMediaQuery {
    .isMobile {
        display: none;
    }
}