.rating {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.star {
    margin-left: 2px;
    transform: rotate(0deg) !important; // always have rating stars oriented vertically, sometimes wrapping containers change icon orientation
}

.first_star {
    transform: rotate(0deg) !important; // always have rating stars oriented vertically, sometimes wrapping containers change icon orientation
}

.halfStar{
    display: inline-flex;
    & svg:last-child{
        position:absolute;
    }


}