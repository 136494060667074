@import 'Styles/breakpoints.module.scss';
@import 'Styles/spacing.module.scss';
@import 'Styles/fonts.module.scss';
@import 'Styles/colors.module.scss';

.headerTopBar {
    display: grid;
    grid-template-columns: 270px 1fr 1fr 5fr 6fr;
    justify-items: center;


    .phone {
        grid-column-start: 2;
        grid-column-end: 6;
        justify-self: flex-start;
        margin: 5px;
        text-decoration: none;
    }

    @media (min-width: 2450px) {
        .phone {
            grid-column-start: 3;
        }
    }

    @media (min-width: 2650px) {
        .phone {
            grid-column-start: 4;
        }
    }

    @include pwaMediaQuery {
        .phone {
            grid-column-start: 3;
        }
    }

    .trackOrder {
        grid-column-start: 9;
        max-width: 102px; //CLS
        white-space: nowrap; //CLS
    }


    .rewards {
        grid-column-start: 6;
        max-width: 76px; //CLS
        white-space: nowrap; //CLS

    }

    .help {
        grid-column-start: 10;
        max-width: 98px; //CLS
        white-space: nowrap; //CLS
    }

    .deals {
        grid-column-start: 8;
        max-width: 55px; //CLS
        white-space: nowrap; //CLS
    }

    .giftCard {
        grid-column-start: 7;
        max-width: 89px; //CLS
        white-space: nowrap; //CLS

    }
}

.headerDesktopView {
    display: grid;
}

.headerMobileView {
    display: none;
}

.pageWidth {
    max-width: 1800px;
}

.headerContainer {
    z-index: 2; // at the time of entering this we place the zindex at 2 to sit above the shop page css.
    position: relative;
}

.closeButton {
    color: white;
}

.closeButtonWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 8px;
}

.modalClose {
    cursor: pointer;
    font-size: 1.25em;
    padding: 7px;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-self: flex-end;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
}

.garagePanel {
    border-radius: 0.5rem;
    padding: 10px;
}

@include tabletMediaQuery {
    .headerTopBar {
        grid-template-columns: 0.5fr 4fr;

        .trackOrder {
            grid-column-start: 8;
        }

        .catalog {
            grid-column-start: 9;
        }

        .deals {
            grid-column-start: 7;
        }

        .phone {
            grid-column-start: 1;
            grid-row-start: 1;
            padding-left: 5px;
        }

        .giftCard {
            grid-column-start: 6;
        }

        .rewards {
            grid-column-start: 5;
            grid-row-start: 1;
        }
    }

    .garageModal {
        max-width: 400px;
        width: 90%;
    }
}

@include mobileMediaQuery {
    .headerTopBar {
        display: none;
    }

    .headerDesktopView {
        display: none;
    }

    .headerMobileView {
        display: flex;
    }


    .garageModal {
        margin-top: 10px;
    }

    .garageModal {
        max-width: 400px;
        width: 90%;
    }



}

.ds9Header {
    grid-template-rows: 65px;
    position: relative;
    margin: 0;
    z-index: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #c4c4c4;
    width: 100%;

    @include fontStandard();

    a {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    // We have to set a column start for IE 11 ....
    @for $i from 1 through 3 {
        .menuItem#{$i} {
            grid-column-start: $i;
        }
    }

    .headerItem {
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .logoHeaderItem {
        padding: 0rem 5px 0rem 5px;
    }

    .searchHeaderItem {
        cursor: auto;
    }

    .buttonGrid {
        display: grid;
        height: 100%;
        width: 100%;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 1fr 1fr 0.25fr;
    }

    .buttonText {
        grid-column-start: 2;
        grid-row-start: 1;
        align-self: end;
    }

    .buttonBoldText {
        grid-column-start: 2;
        grid-row-start: 2;
        align-self: start;
    }

    .buttonSvg {
        margin: 0px 0px 0px 0px;
        grid-column-start: 1;
        grid-row-start: 2;
        align-self: start;
    }

    .searchBar {
        width: 100%;
    }

    .navLeft,
    .navCenter,
    .navRight {
        display: flex;
    }

    .navRight {
        justify-content: flex-end;
    }

    .headerMenu {
        flex: 0 0 70px;
    }

    .headerLogo {
        flex: 0 0 200px;
    }

    .headerShopParts {
        flex: 0 0.9 150px;
    }

    .headerToolbox {
        flex: 0 0.95 150px;
    }

    .headerGarage {
        flex: 0 1 225px;
    }

    .headerGarageTextWrapper {
        max-width: 130px;
    }

    .headerAccountTextWrapper {
        max-width: 100px;
    }

    .headerOverflowText {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .headerAccount {
        flex: 0 1 150px;
    }

    .headerBuildlist {
        flex: 0 1 100px;
    }

    .headerShoppingCart {
        flex: 0 0 70px;
    }

    @include desktopMediaQuery {
        grid-template-columns: minmax(500px, 1fr) minmax(20%, $desktopMaxWidth) minmax(480px, 1fr);

        @include pwaMediaQuery {
            grid-template-columns: minmax(550px, 1fr) minmax(20%, $desktopMaxWidth) minmax(480px, 1fr);
        }


        .logoDesktop {
            display: block;
            height: 90px;
            width: 200px;
            position: absolute;
            bottom: 0px;
        }

        @media screen and (-ms-high-contrast: active),
        (-ms-high-contrast: none) {

            .logoDesktop {
                position: relative;
            }
        }

        .desktopHide {
            display: none;
        }
    }

    @include tabletMediaQuery {
        grid-template-columns: 250px minmax(30%, $tabletMaxWidth) minmax(390px, 1fr);

        @include pwaMediaQuery {
            grid-template-columns: 290px minmax(30%, $tabletMaxWidth) minmax(390px, 1fr);
        }

        .headerLogo {
            flex: 0 0 70px;
        }

        .logoTablet {
            display: block;
            height: 100%;
            width: 100%;
        }

        .logoHeaderItem {
            padding: 0rem;
        }

        .tabletHide {
            display: none;
        }
    }

    @mixin narrowStyling() {
        .logoHeaderItem {
            padding: 0rem;
        }
    }

    @include mobileMediaQuery {
        // We will always set the full 9 columns to make it easy to loop the column start for IE 11
        grid-template-columns: 60px 1fr 0fr;
        border-bottom: none;
        @include narrowStyling();

        &>*:not(:last-child) {
            border-right: none;
        }

        .mobileHide {
            display: none;
        }
    }

    @include mobileLimitedMediaQuery {
        @include narrowStyling();

        &>*:not(:last-child) {
            border-right: none;
        }

        .mobileLimitedHide {
            display: none;
        }
    }
}


@media print {

    .headerContainer,
    .navCenter,
    .navRight {
        display: none !important;
    }
}

.mobileHeaderBlock {
    display: flex;
    justify-content: stretch;
    align-items: center;
    max-height: 4rem;
    overflow: hidden;
}

.mobileIcon {
    width: 4rem;
    height: 4rem;
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobileIcon svg {
    max-width: 100%;
    max-height: 100%;
    margin-top: 1.25rem;

}

.mobileSearchBar {
    flex-basis: 100%;
    flex-grow: 2;
}


@include desktopMediaQuery {
    .mobileHeaderBlock {
        display: none;
    }
}

@include tabletMediaQuery {
    .mobileHeaderBlock {
        display: none;
    }
}