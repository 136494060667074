@import "Styles/breakpoints.module.scss";

.modal {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5em 1em;
  z-index: 999999999;
  box-sizing: border-box;
}

.modalContainer {
  display: flex;
  flex-direction: column;
}

.modalContainer > .modalClose {
  cursor: pointer;
  font-size: 1.25em;
  padding: 7px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-bottom: 8px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
}

.modalClosePaginated {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.modalContainer > .modalBody {
  position: relative;
  margin: 0 auto;
  border-radius: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
}

.modalBodyContainer {
  display: flex;
  align-items: center;
}

.modalBodyPaginated {
  border-radius: 0.5rem;
}

.paginationLeft {
  margin-right: 1rem;
}

.paginationRight {
  margin-left: 1rem;
}

@include mobileLimitedMediaQuery {
  .modalContainer {
    width: 100%;
  }

  .modalBody {
    width: 100%;
  }
}
