.modal {
    margin: 1.5rem;
}

.row {
    display: flex;
    gap: 1rem;
    margin: 1rem;
}

.buttonRow {
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
}