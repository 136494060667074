.title {
  margin-bottom: 0.25rem;
}

.grid_rec_wrapper {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  grid-gap: 1rem;
  justify-content: space-evenly;
}

.double_landing_page_rec_wrapper {
  display: grid;
  grid-template-columns: 1;
  grid-gap: 1rem;

  .double_rec_base {
    margin: 0 auto;
  }
}

.single_rec_wrapper {
  display: flex;
  justify-content: center;
}

.divider {
  margin-bottom: 1rem;
}

.noDivider {
  @extend .divider;
  height: 1px;
}