.container {
    width: 100%;
    text-align: center;
    position: relative;
    padding: 1rem 0 1rem 0
}

.divider {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
