.list {
    padding-left: 0px;
}

.listGridLayout {
    display: grid;
}

.listFlexLayout {
    display: flex;
}