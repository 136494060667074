.container {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.dropdownAddButton {
  margin-top: 0.5rem;
}
