.card {
    width: 12rem;
    overflow: hidden;

    p {
        overflow: hidden;
        white-space: nowrap;
        max-width: 100%;
        text-overflow: ellipsis;
    }
}

.image {
    display: flex;
    justify-content: center;
}

.timeAgo:first-letter {
    text-transform: capitalize;
}