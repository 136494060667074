@import "Styles/spacing.module.scss";
@import "Styles/colors.module.scss";
@import "Styles/transition.module.scss";

.button {
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-origin: border-box;
  box-sizing: border-box;

  @include transition($fast);

  &:hover {
    background-image: none;
  }

  & p {
    @include transition(opacity $fast);
  }

  & svg {
    @include transition(opacity $fast);
  }

  &.noWrap {
    white-space: nowrap;
  }
}

.buttonDisabled {
  cursor: not-allowed;
}

.buttonWaiting {
  position: relative;

  & p,
  svg,
  h2 {
    opacity: 0;
  }

  & .spinnerWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-45%, -45%);
  }

  cursor: not-allowed;
}

.buttonSmall {
  border-radius: 4px;
  padding: $space-s $space-m;
}

.buttonMedium {

  border-radius: $space-pill-border-radius;
  padding: $space-xs $space-m;
}

.buttonLarge {
  padding: $space-m $space-xl;
  border-radius: $space-pill-border-radius;
}

.buttonFill {
  width: 100%;

  .buttonNoChildren {
    width: 100%;
  }
}