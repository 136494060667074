@font-face {
    font-family: 'InterTight-Regular';
    font-display: swap;
    src: url('/Content/Fonts/InterTight-Regular.woff2') format('woff2');
    letter-spacing: 0.02em;
}

@font-face {
    font-family: 'InterTight-Bold';
    font-display: swap;
    src: url('/Content/Fonts/InterTight-Bold.woff2') format('woff2');
    letter-spacing: 0.02em;
}

@font-face {
    font-family: 'InterTight-Header';
    font-display: swap; 
    src: url('/Content/Fonts/InterTight-Bold.woff2') format('woff2');
    
    letter-spacing: 0.02em;
}