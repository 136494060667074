.wrapper{
    position: relative;
    display: inline-flex;
}
.content{
    transition: 0.2s ease-in-out;
    width: 100%;
}
.blocker{
    position: absolute;
    left: 0;
    top: 0;
    background-color: transparent;
    width: 100%;
    height: 100%;
}
.floater{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.fillWidth{
    width: 100%;
}