
@import 'Styles/spacing.module.scss';
.modal{
    &Wrap{
        display: flex;
        flex-direction: column;
        padding: $space-m;
    }
 &Buttons{
    margin-top: $space-m;
    display: flex;
    justify-content: space-around;
    align-items: center;
    & button {
        margin-right: $space-m;
        margin-left: $space-m;
      }
 }

}
