
@import 'Styles/transition.module.scss';
$thickness: 0.2rem;

.spinner{
    border-radius: 50%;
    border: $thickness solid;
    border-bottom: $thickness solid transparent;
    text-indent: -9999em;
    margin: 0.5rem;
    animation: rotating 1s linear infinite, softin 0.25s linear 1 forwards;
    overflow: hidden;
    display: inline-block;
}



@keyframes softin {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

@keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }