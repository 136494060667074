.dataRow:nth-child(even) td{
    background-color: var(--backgroundColor);
}

.dataRow td{
    border: 1px solid var(--borderColor);
    border-collapse: collapse;
}

.dataRow:hover {
    background-color: var(--actionColor);
    --backgroundColor: var(--actionColor);
}

.dataRow th{
    white-space: nowrap;
    text-align: left;
}

.dataRow {
    cursor: default;
}

.compareTable td, .compareTable th{
    padding: 0.25rem;
}

.compareTable td {
    vertical-align: top;
    padding-left: 0.5rem;
}

.productInfoRow td:not(:last-child), .productImageRow td:not(:last-child) {
    border-right: 1px solid var(--borderColor);
}

.compareTable {
    table-layout: fixed;
    width: 100%;
    min-width: 600px;
    position: relative;
}

.imageWrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 8rem;
}

.scroller {
    overflow: auto;
    max-width: 100vw;
    padding: 1rem;
}

.compareTable th{
    white-space: nowrap;    
    transform: translate(0%, -50%);
    overflow: visible;
    width: 0;
    font-size: 0.8rem;
    pointer-events: none;
    position: sticky;
    left: 0;
    text-shadow: 0 0 5px #fff;
}

.ribbon {
    position: absolute;
    margin-left: -3px;
}

.fitment {
    display: flex;
    align-items: center;
  
    svg {
      flex-shrink: 0;
      margin-right: 0.25rem;
    }
}

.universalFitment {
    display: flex;
    flex-direction: column;
    svg {
        flex-shrink: 0;
        margin-right: 0.25rem;
      }
}

.fitmentSvg {
    align-items: center;
    display: flex;
}

.hideMobile{
    display: none;
}

.hideDesktop {
    display: table-cell;
}

@media (min-width: 600px){
    .compareTable th{
        text-overflow: ellipsis;
        overflow: hidden;
        transform: none;
        width: initial;
        font-size: initial;
        pointer-events: initial;
        position: static;
    }

    .hideDesktop {
        display: none;
    }

    .hideMobile {
        display: table-cell;
    }
}