.mainContent {
    text-align: left;
    display: flex;
    padding-left: 18px;
    margin-bottom: 1rem;
}

.toolboxFeaturedArticleSection {
    width: 300px;
    margin-right: 24px;
    display: flex;
    flex-wrap: wrap;

    .featuredArticleWrapper{
        width: 100%;
    }
}

.toolboxMenuSection {
    margin: 12px 13px 0px 16px;

    a:hover{
        text-decoration:underline;
    }
}

.menuSectionContainer {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;    
}

.buttonContainer {
    display: flex;
    padding-left: 18px;
}

.toolboxOption {
    margin-bottom: 20px;
}

.mediaContentSection{
    width: 300px;
}

a.mediaContentSectionTitle {
    display: block;
    margin: 12px 0px 0px 0px;
    width: 100%;

    &:hover {
        text-decoration: underline;
    }
}


.seeAllArticlesSection {
    margin-top: 0.5rem;
    text-align: left;
    display: flex;
    align-items: center;
}

.seeMoreText {
    padding-right: 26px;
    &:hover {
        text-decoration: underline;
    }
}

a.featuredArticleTitle {
    display:block;
    margin: 12px 0px 6px 0px;
    &:hover {
        text-decoration: underline;
    }
}

.featuredArticleContent {
    border: 1px hsla(0, 0%, 0%, 0.15) solid;
    border-radius: 5px;
    width: 100%;
}

a.recommendedArticlesTitle {
    margin-left: 18px;
    text-align: left;
    display: inline-block;
    margin-right: 958px;

    &:hover {
        text-decoration: underline;
    }
}

.mediaContentImage {
    width: 100%;
    height: 185px;
    margin-top: 11px;
}

.recommendedArticleCardWrapper{
    width: 402px;
}

.recommendedArticles {
    padding: 6px 8px 0px 16px;
    text-align: left;
    display: flex;

    .marginWrapper {
        margin-right: 13px;
    }

    .recommendedArticle {
        border: 1px hsla(0, 0%, 0%, 0.15) solid;
        border-radius: 4px;
        padding: 12px 12px 6px 10px;
        width: 402px;

        .recommendedArticleContent {
            height: 66px;
            overflow: hidden;
            display: inline-flex;
            min-width: 0;
            .recommendedArticleImage {
                height: auto;
                width: 80px;
                align-self: center;
                padding-right: 10px;
            }

            .recommendedArticleText {
                width: 300px;

                .recommendedArticleTitle {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .recommendedArticleSummary {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                } 
            }
        }

        .recommendedArticleReadMore {
            text-align: right;
            margin-left: 325px;
            &:hover{
                text-decoration:underline;
            }
        }
    }
}

@media (min-width: 1000px) and (max-width: 1310px){
    .mediaContentSection{
        width: 200px;
        img{
            width: 180px;
            height: 112px;
        }
    }

    .techTipsSection {
        width: 160px;
        margin-left: 16px;
        margin-right: 16px;
    }

    a.recommendedArticlesTitle {
        margin-right: 600px;
    }

    .buildsSection {
        width: 140px;
    }

    .toolboxFeaturedArticleSection{
        width: 200px;
    }

    .recommendedArticleCardWrapper{
        width: 310px;
    }
}
