@import 'Styles/spacing.module.scss';
@import 'Styles/transition.module.scss';

.card {
    margin-top: $space-m;
    border-radius: 4px;
    border: 0;
    text-decoration: none;
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, 0.15);
    overflow: visible;
    @include transition($fast);
}

.cardFill {
    width: 100%;
}

.accent {
    border-left: 0.25rem solid var(--accentColor);

}

.cardFillHeight {
    height: 100%;
    box-sizing: border-box;
}

.card_internal {
    padding: $space-m;
}

.cardContentWrapper {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
}

.cardNoMargin {
    margin: 0;
}

.cardHover:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
    border: 1px solid rgba(0, 0, 0, 0.2);
}


@media print {
    .card {
        box-shadow: none;
        display: block;
    }

    .card_internal {
        padding: 0;
    }
}