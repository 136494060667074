.content{
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
    text-align: left;
}

.topCategories{
 text-align: left;
}

.topCategoryTitle{
    margin-bottom: 1rem;
}

.topCategory{
    border-radius: 2rem;
    margin-right: 1rem;
}