
//these are the propper design system spacing values. Do not use anything else. https://www.figma.com/file/dxTrsJcsSaCMjttM6eI1kO09/Clutch-Design-System?node-id=2152%3A0
$space-xxsm:0.25rem; //4px
$space-xsm:0.5rem; //8px
$space-sm:0.75rem; //12px
$space-md:1rem; //16px
$space-lg:1.5rem; //24px
$space-xlg:2rem; //32px
$space-xxlg:3rem; //48px
$space-jumbo-sm:4rem; //64px
$space-jumbo-md:4.5rem; //72px
$space-jumbo-lg:5rem; //80px





//@depreciated Don't use anything below this line moving forward




// If you want to add extra sizes, bring it up in the Clutch meeting ( don't do it )
$space-xs: 0.25rem;
$space-s: 0.5rem;
$space-m: 1rem;
$space-l: 2rem;
$space-xl: 4rem;

// If you want to add extra sizes, bring it up in the Clutch meeting ( don't do it )
$space-inset-xs: $space-xs $space-xs $space-xs $space-xs;
$space-inset-s: $space-s $space-s $space-s $space-s;
$space-inset-m: $space-m $space-m $space-m $space-m;
$space-inset-l: $space-l $space-l $space-l $space-l;
$space-inset-xl: $space-xl $space-xl $space-xl $space-xl;

// If you want to add extra sizes, bring it up in the Clutch meeting ( don't do it )
$space-stack-xs: 0 0 $space-xs 0;
$space-stack-s: 0 0 $space-s 0;
$space-stack-m: 0 0 $space-m 0;
$space-stack-l: 0 0 $space-l 0;
$space-stack-xl: 0 0 $space-xl 0;

// If you want to add extra sizes, bring it up in the Clutch meeting ( don't do it )
$space-inline-xs: 0 $space-xs 0 0;
$space-inline-s: 0 $space-s 0 0;
$space-inline-m: 0 $space-m 0 0;
$space-inline-l: 0 $space-l 0 0;
$space-inline-xl: 0 $space-xl 0 0;

$space-pill-border-radius: 16rem;

@mixin transform($prop) {
    -webkit-transform: $prop;
    -moz-transform: $prop;
    -o-transform: $prop;
    -ms-transform: $prop;
}

@mixin clear() {
    *zoom: 1;

    &:before,
    &:after {
        display: table;
        content: '';
    }

    &:after {
        clear: both;
    }
}
