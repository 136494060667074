@import 'Styles/spacing.module.scss';

.vertical_product_card {
  width: 12rem;
  // transition-property: transform;
  // transition-duration: 300ms;
  // transition-delay: 50ms;
}

// .vertical_product_card:hover {
//   transform: scale(1.3) translateY(1.5em);
// }

.horizontal_product_card {
  display: flex;
  position: relative;
}

.image {
  text-align: center;
}

.vertical_image {
  margin-right: auto;
  margin-left: auto;
}

.horizontal_image {
  flex-shrink: 0;
}

.horizontal_rating {
  margin-bottom: $space-xsm;
  margin-top: $space-xxsm;
  margin-left: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.vertical_rating {
  margin: auto;
  margin-left: 0;
  // display: block;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.title {
  white-space: normal;

  &:hover {
    text-decoration: underline;
  }
}

// .review_count {
//   vertical-align: bottom;
// }

.strike_through_price {
  margin-left: 0.5rem;
}

.ribbon {
  width: 4rem;
  margin-bottom: 0.75rem;

  &.ribbon_hidden {
    visibility: hidden;
  }
}

.horizontalContent {
  padding-left: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.horizontalPricing {
  display: inline-flex;
}
