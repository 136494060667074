.recentSearchesPanel {   
    border-radius: 5px;
    margin-top: 0.75rem;
    .headingText {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    hr {
        margin-top: 5px;
        margin-bottom: 0px;
    }
    .clearSearchHistory {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
    .recentSearchList{
        margin: 5px 0px 5px 0px;
        display: flex;
        flex-direction: column;
        .recentSearchLink {
            text-decoration: none;
            overflow: hidden;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}

@media(min-width: 1024px){
    .recentSearchesPanel {
        .recentSearchList{        
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
}

.recentSearchButton{
    margin-top: 0.5rem;
    border-radius: 2rem;
    width: fit-content;
}