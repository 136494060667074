.verticalCard {
  width: 12rem;
}

.articleCard {
  width: 14rem;
}

.horizontalCard {
  max-width: 35rem;
}

.reducedArticleCard {
  width: 17.5rem;
}

.search_term {
  text-transform: capitalize;
  border-radius: 2rem;
}