.container {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.dropdownLinks {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 0.5rem;
  margin-bottom: 0rem;
}

.garageFlavorText {
  p {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
  }
}
