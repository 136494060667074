@import 'Styles/spacing.module.scss';
@import 'Styles/breakpoints.module.scss';

.headerSearch {
    border-radius: $space-pill-border-radius;
    &:hover {
        border-color:hsla(289,60%,32%,1);
    }
    
}


.hideDesktop{
    display: none;
}


@include mobileLimitedMediaQuery {
    .hideMobile{
        display: none;
    }

    .hideDesktop{
        display: flex;
    }
}