.container {
  padding-top: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  align-items: center;
}

.linkContainer {
  display: grid;
  gap: 12px;
  flex-grow: 1;
}

.linkContainerNoImages {
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
}

.linkContainerImages {
  grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
}

.repImageContainer {
  display: none;
}

.showMoreLink {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.button {
  border-radius: 50px;
}

@media (min-width: 1024px) {
  .repImageContainer {
    display: block;
    margin-right: 22px;
  }

  .image {
    border: 1px solid var(--borderColor);
  }
}

@media (max-width: 400px){
  .linkContainerNoImages {
    grid-template-columns: none;
  }
}