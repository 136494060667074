@import 'Styles/spacing.module.scss';
@import 'Styles/transition.module.scss';

.area {
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
    padding: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.area_fill {
    width: 100%;
}

.area_no_padding{
    padding: 0em;
    padding-left: 0.25em;
    padding-right: 0.25em;
}

.area_no_margin{
    margin-left: 0rem;
    margin-right: 0rem;
}


.area_icon_wrapper {
    display: flex;
    justify-content: flex-start;
}

.area_icon_centered {
    align-self: flex-start;
}

.area_content {
    align-self: center;
}


.area_icon {
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    display: flex
}

.area_icon_svg {
    margin: auto;
}