@import "Styles/breakpoints.module.scss";

.outerDiv {
  display: flex;
  flex-direction: column;
}

.contentDiv {
  width: 100%;
  display: flex;
  max-height: var(--h);
  overflow: hidden;
  position: relative;
  flex-direction: column;
}

.contentDivMargin {
  margin-bottom: 0.5rem;
}

.gradient {
  background: linear-gradient(to top, var(--c), rgba(255, 255, 255, 0) 100%);
  position: absolute;
  bottom: 0;
  height: 50%;
  width: 100%;
}

.buttonDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}

.buttonAbsolute {
  position: absolute;
  bottom: 0rem;
}

.buttonWrapper {
  display: block;
}

.buttonWrapper:hover button {
  background-color: var(--b);
}

.testDeleteThis {
  margin-top: 0px;
}

@media(min-width: 750px) {
  .mobileOnly {
    .buttonWrapper {
      display: none;
    }

    .contentDiv {
      max-height: none;
    }
  }
}