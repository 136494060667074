.container {
  padding-left: 0.5rem;
  border-bottom: 1px solid var(--borderColor);
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
}

.backButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin-right: 1rem;
}

.closeIcon {
  margin-left: auto;
  cursor: pointer;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.svg {
  margin-right: 1rem;
}