$mobileLimitedMaxWidth: 460px;
$mobileMaxWidth: 1023px;
$tabletMaxWidth: 1335px;
$desktopMaxWidth: 1335px;
$mobileOnlyMaxWidth: 767px;


@mixin mobileLimitedMediaQuery {
    @media (max-width: $mobileLimitedMaxWidth){
        @content;
    }
}
@mixin mobileMediaQuery {
    @media (max-width: $mobileMaxWidth){
        @content;
    }
}
@mixin mobileOnlyMediaQuery {
    @media (max-width: $mobileOnlyMaxWidth){
        @content;
    }
}
@mixin desktopListMediaQuery {
    @media (min-width: $mobileOnlyMaxWidth + 1px) {
        @content;
    }
}
@mixin desktopMediaQuery {
    @media (min-width: $desktopMaxWidth + 1px) {
        @content;
    }
}
@mixin tabletMediaQuery {
    @media (min-width: $mobileMaxWidth + 1px) and (max-width: $tabletMaxWidth) {
        @content;
    }
}

@mixin desktopAndTabletMediaQuery {
    @media (min-width: $mobileOnlyMaxWidth + 1px) {
        @content;
    }
}

@mixin pwaMediaQuery {
    @media (display-mode: standalone),
        (display-mode: fullscreen) {
        @content;
    }

    :global(body.enablePwaStyling) {
        @content;
    }
}