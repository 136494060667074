.center {
  text-align: center;
}

.atc_drawer_header {
  margin: 1.875rem;
}

.atc_drawer_header_icon {
  margin-right: 1rem;
}

.atc_drawer_panel {
  padding: 1.5rem;
  flex-grow: 1;
}

.shipping_meter_panel {
  padding: 1rem 1.5rem;
}

.shipping_meter {
  margin-top: 0.25rem;
}

.atc_drawer_panel_footer {
  padding: 1.5rem;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.atc_drawer_subtotal {
  float: right;
}

.atc_drawer {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.atc_drawer_image_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.atc_drawer_panel_footer_divider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.atc_drawer_panel_footer_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.atc_drawer_continue_shopping {
  margin-top: 0.5rem;
}
