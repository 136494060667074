@import "Styles/breakpoints.module.scss";

.yourVehicles {
  display: flex;
}

.isSelected {
  margin-top: 8px;
  margin-right: 4px;
}

.space {
  margin-left: 20px;
}

.vehicleTitleText {
  text-transform: uppercase;
  flex-grow: 1;
  text-align: left;
  max-width: 378px;
  padding-right: 10px;
}

.vehicleTrash {
  flex: 1 1 auto;
  justify-content: flex-end;
}

.vehiclePrimaryText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.vehicleDivider {
  margin-top: 2px !important;
  color: #d3d3d3 !important;
}

.vehicleListWrapper {
  margin-top: 5px;
}

.selectedCustomerProjectIcon {
  margin-right: 5px;
}

.vehicleContainer {
  align-items: center;
}

.customerProjects {
  border: 1px solid black;
}

.vehicleDivider {
  margin-bottom: 0px;
}

@include mobileMediaQuery {
  .vehicleTitleText {
    max-width: 300px;
    min-width: 100px;
  }
}
