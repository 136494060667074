@import 'Styles/breakpoints.module.scss';
@import 'Styles/fonts.module.scss';

.nonNativeSelect{
    display: none;
}

.nativeSelect{
    display: block;
}

.nonNativeSelectAlwaysEnabled {
    display: block;
    @include fontStandard();
}

@include desktopAndTabletMediaQuery {
    .nonNativeSelect {
        display: block;
        @include fontStandard();
    }

    .nativeSelect {
        display: none;
    }
}
