@import 'Styles/spacing.module.scss';
@import 'Styles/breakpoints.module.scss';

$content-width: 1800px;

.gutters{
    width: 100%;
    box-sizing: border-box;
    padding: $space-m $space-s;
}

.gutters_no_vertical_padding {
    padding-left: $space-s;
    padding-right: $space-s;
}

.gutters.gutters_no_right_padding, .gutters_no_vertical_padding.gutters_no_right_padding {
    padding-right: 0;
}

.gutters_no_horizontal_padding, .gutters_no_vertical_padding.gutters_no_horizontal_padding {
    padding-left: 0;
    padding-right: 0;
}

//128px is 8rem, this insures theres always going to be at least 4 rem of padding per side
@media (min-width: calc(#{$content-width} + 128px)) {
    .gutters{
        padding: $space-l calc(50vw - (#{$content-width} / 2)) $space-l calc(50vw - (#{$content-width} / 2));
    }
    .gutters_no_vertical_padding {
        padding-left: calc(50vw - (#{$content-width} / 2));
        padding-right: calc(50vw - (#{$content-width} / 2));
    }
    .gutters.gutters_no_right_padding, .gutters_no_vertical_padding.gutters_no_right_padding {
        padding-right: 0;
    }

    .gutters_no_horizontal_padding, .gutters_no_vertical_padding.gutters_no_horizontal_padding {
        padding-left: 0;
        padding-right: 0;
    }

}

//127px is 8rem - 1px, this insures theres always going to be at least 4 rem of padding per side
@media (min-width: 1200px) and (max-width: calc(#{$content-width} + 127px)) {
    .gutters{
        padding: $space-l $space-xl;
    }
    .gutters_no_vertical_padding {
        padding-left: $space-xl;
        padding-right: $space-xl;
    }
    .gutters.gutters_no_right_padding, .gutters_no_vertical_padding.gutters_no_right_padding {
        padding-right: 0;
    }

    .gutters_no_horizontal_padding, .gutters_no_vertical_padding.gutters_no_horizontal_padding {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .gutters{
        padding: $space-l $space-l;
    }
    .gutters_no_vertical_padding {
        padding-left: $space-l;
        padding-right: $space-l;
    }
    .gutters.gutters_no_right_padding, .gutters_no_vertical_padding.gutters_no_right_padding {
        padding-right: 0;
    }

    .gutters_no_horizontal_padding, .gutters_no_vertical_padding.gutters_no_horizontal_padding {
        padding-left: 0;
        padding-right: 0;
    }
}

