.ribbon {
    padding-left: 0.25rem;
    padding-right: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    position: absolute;
    display: inline-block;
    transform: skew(-21.25deg);
    z-index: 1;

    &:before {
        content: "";
        transform: skew(21.25deg);
        top: 0;
        bottom: 0;
        left: var(--f);
        width: 1.5rem;
        position: absolute;
    }
}

.ribbon_text {
    transform: skew(21.25deg);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.uppercase {
    text-transform: uppercase;
}

.ribbon_svg {
    margin-right: 0.5rem;
}

.dimensional {
    &:before {
        background-color: var(--c);
        box-shadow: -18px 11px var(--b) var(--d);
        clip-path: polygon(
            0 0,  
            100% 0,
            100% 100%,
            calc(0% - var(--f)) 100%,
            calc(0% - var(--f)) calc(100% + var(--b)),
            0 100%,
            0 0
          );
    }
}