@import './intertight.module.scss';

@mixin fontStandard() {
  font-family: InterTight-Regular, sans-serif;
  font-style: normal;
}

@mixin fontBold() {
  font-family: InterTight-Bold, sans-serif;
  font-style: normal;
  letter-spacing: initial;
}

@mixin fontHeading() {
  font-family: InterTight-Header, sans-serif;
  font-style: normal;
}
