@import 'Styles/spacing.module.scss';
@import 'Styles/transition.module.scss';

$divider-height: 1px;

.divider {
    position: relative;

    display: block;
    clear: both;
    text-align: left;
    height: $divider-height;
    width: 100%;

    &:before {
        content: '';
        position: absolute;
        border-top-style: solid;
        border-top-width: $divider-height;
        width: 100%;
    }
}