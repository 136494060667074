.wrapper {
    text-align: center;
    margin-top: 3rem;
}

.links {
    margin: 0.5rem;
    display: flex;
    justify-content: center;
}

.wrapper a {
    margin: 0.5rem;
}

.image {
    margin: 1rem;
    max-width: 90%;
    height: auto;
}

.recWrapper {
    margin-top: 2rem;
}