@import './breakpoints.module.scss';
@import './spacing.module.scss';
@import './fonts.module.scss';
@import './colors.module.scss';
@import './transition.module.scss';

body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

@include mobileMediaQuery {
    body {
        margin-bottom: 10vh;
    }
}

*::-webkit-scrollbar {
    background-color: hsl(0, 0%, 95%);
    width: 0.875rem;
    height: 0.875rem;
}


*::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color, hsl(0, 0%, 80%));
    border-radius: 50px;
    border: 1px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    @include transition(background-color $fast);

    &:hover {
        background-color: var(--scrollbar-thumb-color-hover, hsl(0, 0%, 70%));
    }
}


*::-webkit-scrollbar-track-piece {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

/* Buttons */
*::-webkit-scrollbar-button:single-button {
    background-color: transparent;
    display: block;
    width: 0px;
    height: 4px;
}

/* Up */
*::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-width: 0 4px 8px 4px;
    border-color: transparent transparent var(--scrollbar-thumb-color, hsl(0, 0%, 90%)) transparent;
    border-style: solid;
}

*::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    border-color: transparent transparent var(--scrollbar-thumb-color-hover, hsl(0, 0%, 80%)) transparent;
}

/* Down */
*::-webkit-scrollbar-button:single-button:vertical:increment {
    border-width: 8px 4px 0 4px;
    border-color: var(--scrollbar-thumb-color, hsl(0, 0%, 90%)) transparent transparent transparent;
    border-style: solid;
}

*::-webkit-scrollbar-button:vertical:single-button:increment:hover {
    border-color: var(--scrollbar-thumb-color-hover, hsl(0, 0%, 80%)) transparent transparent transparent;
}


//start typography

a.p {
    text-decoration: none;
}

//standard stylings with low specificity to make it easy to override
.p {
    font-weight: normal;
    font-size: calc(var(--sizeMod, 1) * 1rem);
    line-height: calc(var(--sizeMod, 1) * 1.5rem);
    --toneMod: 0.8;
    margin: 0;
    @include fontStandard();
    color: hsla(0, 0%, calc(var(--lightMod, 0) * 1%), var(--toneMod, 0.8));
}

//these get light text
.bg-dark,
.bg-color-primary,
.bg-color-secondarypalette_purple,
.bg-color-secondarypalette_blue,
.bg-color-secondarypalette_black,
.bg-color-positive,
.bg-color-negative,
.bg-color-action {
    --invertedMod: 0;
    --lightMod: 100;
}

//these get dark text
.bg-light,
.bg-color-secondary,
.bg-color-secondarypalette_orange,
.bg-color-warning {
    --invertedMod: 100;
    --lightMod: 0;
}

//flip the color
.layer-inverse {
    --lightMod: var(--invertedMod, 100);
}

//layer depths

.layer-0 {
    --layerMod: 0.95;
    --hoverLayerMod: 1;
}

.layer-1 {
    --layerMod: 0.9;
    --hoverLayerMod: 0.95;
}

.layer-2 {
    --layerMod: 0.85;
    --hoverLayerMod: 0.9;
}

.layer-3 {
    --hoverLayerMod: 0.85;
    --layerMod: 0.82;
}



.layer-inverse.layer-0 {
    --layerMod: 1.05;
    --hoverLayerMod: 1;
}

.layer-inverse.layer-1 {
    --layerMod: 1.1;
    --hoverLayerMod: 1.05;
}

.layer-inverse.layer-2 {
    --layerMod: 1.15;
    --hoverLayerMod: 1.1;
}

.layer-inverse.layer-3 {
    --layerMod: 1.17;
    --hoverLayerMod: 1.15;
}


.p-0_5 {
    --sizeMod: 0.5;
}

.p-0_625 {
    --sizeMod: 0.625;
}

.p-0_75 {
    --sizeMod: 0.75;
}

.p-0_875 {
    --sizeMod: 0.875;
}

.p-1 {
    --sizeMod: 1;
}

.p-1_25 {
    --sizeMod: 1.25;
}

.p-1_5 {
    --sizeMod: 1.5;
}

.p-1_75 {
    --sizeMod: 1.75;
}

.p-2 {
    --sizeMod: 2;
}

.p-2_5 {
    --sizeMod: 2.5;
}

.p-3 {
    --sizeMod: 3;
}



//size utilities
.p-xs {
    --sizeMod: 0.5;
}

.p-s {
    --sizeMod: 0.75;
}

.p-l {
    --sizeMod: 1.25;
}

.p-xl {
    --sizeMod: 1.5;
}

.p-xxl {
    --sizeMod: 2;
}


//styles
.p-underline.p,
.p-hover-underline:hover.p,
.p-hover-underline:active.p {
    text-decoration: underline;
}

.p-strikethrough.p {
    text-decoration: line-through;
}

.p-hover.p,
.p-hover-underline:hover.p {
    cursor: pointer;
    @include transition($fast);
}

.p-hover:hover.p,
.p-hover:active.p {
    //tone is used for hover effects white/black text, layermod for intents
    --toneMod: 1;
    --layerMod: var(--hoverLayerMod, 1)
}

.p-condensed.p {
    line-height: initial;
}

.p-fill.p {
    width: 100%;
}

.p-inline.p {
    display: inline;
}

//tone
.p-color-contrast.p {
    --toneMod: 1;
}

.p-color-subtle.p {
    --toneMod: 0.65;
}

.p-color-superfluous.p {
    --toneMod: 0.4;
}

*[class*="bg-color-"]:not(.bg-color-none) .p {
    //tone is always 1 on colored backgrounds
    --toneMod: 1;
}


//intents and brands

//stuff like ".btn-invert.bg-intent-positive" stuff is just a stubb to get this playing nicely with the current button implementation
//if button gets converted, its styling should handle this stuff (and should generally be kept isolated from typography, the interdependency there should be removed!!)

.p-color-positive.p,
.btn-invert.bg-color-positive:not(:hover) .p {
    color: hsl(150, 100%, calc(31% - (100% - (100% * var(--layerMod, 1)))));
}

.layer-inverse .p-color-positive.p,
.layer-inverse .btn-invert.bg-color-positive:not(:hover) .p {
    color: hsl(132, 77%, calc(39% - (100% - (100% * var(--layerMod, 1)))));
}

.p-color-negative.p,
.layer-inverse .p-color-negative.p,
.btn-invert.bg-color-negative:not(:hover) .p {
    color: hsl(5, 100%, calc(50% * var(--layerMod, 1)));
}

.p-color-action.p,
.layer-inverse .p-color-action.p,
.btn-invert.bg-color-action:not(:hover) .p {
    color: hsl(210, 77%, calc(46% * var(--layerMod, 1)));
}

.p-color-warning.p,
.layer-inverse .p-color-warning.p,
.btn-invert.bg-color-warning:not(:hover) .p {
    color: hsl(26, 81%, calc(52% * var(--layerMod, 1)));
}

.p-color-secondarypalette_black.p,
.btn-invert.bg-color-secondarypalette_black:not(:hover) .p {
    color: hsl(0, 0%, 0%);
}







.p-color-primary.p,
.btn-invert.bg-color-primary:not(:hover) .p {
    color: rgb(70, 10, 102);
}

.p-color-secondary.p,
.btn-invert.bg-color-secondary:not(:hover) .p {
    color: rgb(255, 199, 46);
}

.p-color-secondarypalette_orange.p,
.btn-invert.bg-color-secondarypalette_orange.p:not(:hover) .p {
    color: hsla(26, 81%, 52%, 1);
}

.p-color-secondarypalette_purple.p,
.btn-invert.bg-color-secondarypalette_purple:not(:hover) .p {
    color: hsla(289, 60%, 32%, 1);
}

.p-color-secondarypalette_blue.p,
.btn-invert.bg-color-secondarypalette_blue:not(:hover) .p {
    color: hsla(195, 100%, 44%, 1);
}



//fonts
.p.p-font-bold,
.p.p-bold {
    @include fontBold();
}

.p.p-font-heading {
    @include fontHeading();
}

//animations
.fadeIn {
    animation-name: fadeInAnimation;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-duration: .3s;
}

@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: initial;
    }
}