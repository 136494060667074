
.kit_item_wrapper {
  display: block;
}

.kit_item_count {
  margin-top: 1.5rem;
}

.kit_item_text_spacer {
  margin-top: 0.75rem;
}

.kit_item_image_wrapper {
  width: 100%;
  text-align: center;
  float: left;
}

.kit_avail {
  margin-top: 1rem;
}

@media (min-width: 460px){
  .kit_item_wrapper {
    display: flex;
  }
  
  .kit_item_image_wrapper {
    width: auto;
    margin-right: 1rem;
  }
}