@import 'Styles/transition.module.scss';
@import 'Styles/fonts.module.scss';

.select {
    margin: 0px;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
    line-height: inherit;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    padding: 8px;
    padding-right: 16px;
    height: 40px; // This matches button height, form elements default at same size
    outline: none;

    @include transition(border-color $fast);

    & option:disabled {
        color: #aaa;
    }
}

.regular {
    @include fontStandard();
}

.bold {
    @include fontBold();
}

.heading {
    @include fontHeading();
}

.label {
    margin: 0 0 5px 0;
    padding: 0px;
}

.select:disabled {
    border: 1px solid lightgrey;
    color: lightgrey;
    background: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="lightgrey"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>') 100% 50% no-repeat #fff;
}



.inline {
    display: flex;
    align-items: baseline;

    label {
        white-space: nowrap;
        margin-right: 0.5rem;
    }
}