.accountNavigation {
    width: 200px;
}

.accountNavigationBody {
    .listItem {
        padding: 10px 10px 10px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        >.listItemIcon {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}